import React, { useState } from "react";

export default function DiscordInput({ input, handleChange }) {
    const [value, setValue] = useState('')

    return (
        <label htmlFor={`${input}input`}>{input} of meeting
            <input
                id={`${input}input`}
                name={`${input}input`}
                className="alt"
                onChange={e => { handleChange(e, setValue); }}
                type={input}
                value={value}
            />
        </label>
    )
}