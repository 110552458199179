import React from 'react'


export default function Toaster({ message }) {

    return (
        <div className="toast animation">
            <div className="toast-message">{message}</div>
        </div>
    )

}