import React, { useState, useEffect } from 'react';
import Toaster from './Toaster';


export default function DiscordTimeTable({ time }) {
    const [toaster, setToaster] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setToaster(false)
        }, 1000)
    }, [toaster])

    // CREATES A TABLE THAT CONVERTS TIME IN, TO DISCORD TIME STAMP 
    const DATE = new Date(time * 1000)

    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const day = DATE.getDate()
    const dayOfWeek = DAYS[DATE.getDay()]
    const month = MONTHS[DATE.getMonth()]
    const year = DATE.getFullYear()
    const shortTime = DATE.toLocaleTimeString().replace(':00', '')
    const longTime = DATE.toLocaleTimeString()

    const discordTimeFormats = [
        {
            // 03/20/2022
            syntax: `<t:${time}:d>`,
            example: DATE.toLocaleDateString()
        },
        {
            //	March 20, 2022
            syntax: `<t:${time}:D>`,
            example: `${month} ${day}, ${year}`
        },
        {
            // 10:03 AM
            syntax: `<t:${time}:t>`,
            example: shortTime
        },
        {
            //10:03:00 AM
            syntax: `<t:${time}:T>`,
            example: longTime
        },
        {
            //March 20, 2022 10: 03 AM
            syntax: `<t:${time}:f>`,
            example: `${month} ${day}, ${year} ${shortTime}`
        },
        {
            //March 20, 2022 10:03 AM
            syntax: `<t:${time}>`,
            example: `${month} ${day}, ${year} ${shortTime}`
        },
        {
            //Sunday, March 20, 2022 10:03 AM
            syntax: `<t:${time}:F>`,
            example: `${dayOfWeek}, ${month} ${day}, ${year} ${shortTime}`
        }
    ]

    return (
        <div className='table-wrapper'>
            {toaster && <Toaster message="Copied to Clipboard" />}
            <table>
                <thead>
                    <tr>
                        <th>CHAT SYNTAX</th>
                        <th>EXAMPLE RESULT</th>
                    </tr>
                </thead>
                <tbody>
                    {discordTimeFormats.map(({ syntax, example }) => {
                        return (
                            <tr>
                                <td>
                                    {/* DISCORD SYNTAX */}
                                    <button
                                        className='button fit discord'
                                        value={syntax}
                                        title="Click to copy to clipboard"
                                        onClick={e => {
                                            e.preventDefault()
                                            const syntax = e.target.value
                                            // Copies syntax to clipboard
                                            navigator.clipboard.writeText(syntax).then(function () {
                                                /* clipboard successfully set */
                                                setToaster(true)
                                            }, function () {
                                                /* clipboard write failed */

                                            });
                                        }}
                                    >
                                        {/* Clipboard Icon */}
                                        <i
                                            className="fas fa-clipboard icon"
                                        />
                                        {/* Button Text */}
                                        {syntax}

                                    </button>
                                </td>

                                {/* EXAMPLE OF DISCORD OUTPUT */}
                                <td>{example}</td>

                            </tr>)
                    }
                    )
                    }
                </tbody>
            </table>
        </div>
    )
}