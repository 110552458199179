import React, { useState } from "react";
import DiscordTimeTable from './discordTimeTable'
import DiscordInput from "./DiscordInput";


export default function DiscordTime() {
    // OVER ALL TIME STAMP COMPONENT WITH INPUTS AND TABLE
    const [dateSec, setDateSec] = useState(0)
    const [timeSec, setTimeSec] = useState(0)

    const handleDateChange = (e, usestate) => {
        // get value selected by user
        const [year, month, day] = e.target.value.split('-')
        // display the value in date input
        // we split and send to avoid issue with JS returning wrong date 
        // when sending in date obj by sending specificly formated date
        usestate(`${year}-${month}-${day}`)
        // convert date to sec by getting new date object and divide by 1000 ms
        const dateToSec = (new Date(year, month - 1, day).getTime()) / 1000
        // store value in state
        setDateSec(dateToSec)
    }

    const handleTimeChange = (e, usestate) => {
        // display selected time 
        usestate(e.target.value)
        // get the hour and min
        const [hour, min] = e.target.value.split(':')
        // convert hour to secs
        const hourToSec = Number(hour) * 60 * 60
        // convert min to sec
        const minToSec = Number(min) * 60
        // store value in state
        setTimeSec(hourToSec + minToSec)
    }

    return (
        <div
            className="box"
        >
            <div>
                <DiscordInput input='date' handleChange={handleDateChange} />
                <br />
                <DiscordInput input='time' handleChange={handleTimeChange} />
            </div>
            <br />
            <div>
                <DiscordTimeTable time={dateSec + timeSec} />
            </div>
        </div>

    )
}